<template>
  <b-card
    no-body
    class="coupon-card"
  >
    <b-card-header>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center w-100"
        style="gap: 8px;"
      >
        <b-form-group class="mb-0">
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t('Search') }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
              style="width: 220px;"
            />
          </div>
        </b-form-group>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          to="add"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('Create new') }}
        </b-button>
      </div>
    </b-card-header>
    <b-card-body class="p-0">
      <!-- table -->
      <vue-good-table
        ref="promotion-products"
        class="custom-good-table custom-good-table-ctrl-border"
        style-class="vgt-table"
        :columns="fields"
        :rows="items"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <!-- Table: Head -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>

        <!-- Table: Rows -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'title'"
            class="d-flex flex-wrap align-items-center"
          >
            <!-- <b-avatar
              :src="props.row.avatar"
              size="lg"
              class="mr-1"
              square
            /> -->
            <router-link
              class="d-block line-clamp"
              style="max-width: 320px;"
              :title="props.row.title"
              :to="`edit/${props.row.id}?lang=${currentLang}`"
            >
              {{ props.row.title }}
            </router-link>
          </span>

          <!-- Column: Position -->
          <span
            v-else-if="props.column.field === 'position'"
            class="d-block text-center"
          >
            {{ props.row.position }}
          </span>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'is_active'"
            class="d-block text-center"
          >
            <b-badge
              :variant="props.row.is_active ? 'light-success' : 'light-dark'"
              style="max-width: 140px;"
            >
              {{ $t( props.row.is_active ? 'product_status_active' : 'product_status_disable') }}
            </b-badge>
          </span>

          <!-- Column: Languages -->
          <span v-else-if="props.column.field === 'lang'">
            <template>
              <div class="text-center">
                <LanguageSelect
                  :value="props.row"
                  :language-list="languageList"
                />
              </div>
            </template>
          </span>

          <!-- Column: Actions -->
          <span
            v-else-if="props.column.field === 'actions'"
            class="d-block text-center"
          >
            <b-button
              variant="flat-success"
              :class="['btn-icon rounded-circle', { disabled: items[props.index - 1] === undefined }]"
              @click="handlerSort(props, 'up')"
            >
              <feather-icon
                icon="ArrowUpIcon"
                class="text-success"
                size="18"
              />
            </b-button>
            <b-button
              variant="flat-danger"
              :class="['btn-icon rounded-circle', { disabled: items[props.index + 1] === undefined }]"
              @click="handlerSort(props, 'down')"
            >
              <feather-icon
                icon="ArrowDownIcon"
                class="text-danger"
                size="18"
              />
            </b-button>
            <b-button
              variant="flat-dark"
              class="btn-icon rounded-circle"
              :to="`edit/${props.row.id}?lang=${currentLang}`"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
            <b-button
              variant="flat-danger"
              class="text-danger btn-icon rounded-circle"
              @click="handlerDelete(props.row.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap p-2">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BButton,
  BCard,
  BCardHeader,
  // BCardTitle,
  BCardBody,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BPagination,
  // BAvatar,
  BFormSelect,
  BBadge,
  // BDropdown,
  // BDropdownItem,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LanguageSelect from '@/views/components/language-select/language_v2.vue'
import general from '@/mixins/index'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    // BCardTitle,
    BCardBody,
    // flatPickr,
    BFormInput,
    BFormGroup,
    BPagination,
    // BAvatar,
    BFormSelect,
    BBadge,
    VueGoodTable,
    // BDropdown,
    // BDropdownItem,
    LanguageSelect,
    // BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fields: [
        { field: 'title', label: 'Category', sortable: true },
        { field: 'position', label: 'Position', sortable: false },
        { field: 'is_active', label: 'Status', sortable: false },
        { field: 'lang', label: 'Language', sortable: false },
        { field: 'actions', label: 'Actions', sortable: false },
      ],
      items: [],
      languageList: null,
      pageLength: 10,
      searchTerm: '',
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
    }
  },
  mounted() {
    this.loadLanguages()
    this.loadList()
  },
  methods: {
    async handlerSort(data, method) {
      const { row } = data
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [row.id, this.items[data.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [row.id, this.items[data.index + 1].id]
        }
        if (method === 'change') {
          params.type = 2
          params.ids = [
            {
              id: row.id,
              position: Number(row.position),
            },
          ]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/recruitment_category/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/recruitment_categories?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = await this.getDataTranslation(
              res.data.data.items,
              true,
            )
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/recruitment_category/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Recruitment Category has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loadLanguages() {
      this.languageList = await this.loadLanguage()
    },
  },
}
</script>
<style lang="scss">
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
</style>
